import logo from "./logo.svg";
import { Admin, Resource } from "react-admin";
import "bootstrap/dist/css/bootstrap.min.css";
import API_URL from "./url";

//importing components
import Dashboard from "./dashboard";
import CountryList from "./lists/country";
import ReportList from "./lists/reports";
import TemplateList from "./lists/templates";

//importing functions and objects
import custmDataProvider from "./dataProvider/index";
import MyAuthProvider from "./authProvider/index";
import siteList from "./lists/sites";
import SendNotification from "./sendNotification";
import Integrate from "./integrate";
import ChangePassword from "./changePassword";
import CreateTemplate from "./creates/createTemplate";
import Schedule from "./lists/schedule";

let dp = new custmDataProvider(API_URL);
//"https://ingame-item-topup-api.herokuapp.com/api"
//"http://localhost:80/api"
//"http://localhost:8000/api"
//"https://allinonepush.live/api"
console.log(dp.getList());
let myap = new MyAuthProvider(
  //"https://ingame-item-topup-api.herokuapp.com/api"
  //"http://localhost:8000/api"
  //"https://allinonepush.live/api"
  API_URL
);

function App() {
  return (
    <Admin dataProvider={dp} authProvider={myap} dashboard={Dashboard}>
      <Resource name="Sites" list={siteList} />
      <Resource name="Country Stats" list={CountryList} />
      <Resource name="Reports" list={ReportList} />
      <Resource name="Templates" list={TemplateList} create={CreateTemplate} />
      <Resource name="Send Notification" list={SendNotification} />
      <Resource name="Schedule" list={Schedule} />
      <Resource name="How To Integrate" list={Integrate} />
      <Resource name="Accounts" list={ChangePassword} />
    </Admin>
  );
}

export default App;
